import StoreInfo from '@common/models/StoreInfo';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import Facebook from '@images/FacebookFoot.svg';
import Instagram from '@images/Instagram.svg';
import Linkedin from '@images/Linkedin.svg';
import Pinterest from '@images/Pinterest.svg';
import Twitter from '@images/TwitterFoot.svg';
import Youtube from '@images/Youtube.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';

interface FooterProps {
  storeInfo: StoreInfo;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const quickLinks =
    'text-center md:text-left md:w-full w-fit mx-auto md:mx-0 mb-14 md:mb-20 flex text-cart-button_Variant text-16 leading-22 font-normal';
  const header = 'text-center md:text-left text-footerHeader capitalize leading-24 text-21 mb-16 font-semibold';
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div className="w-full pt-40 pb-30 bg-neutral" color-customisation="footer-bg" id="page-footer">
      <div className="container mx-auto footer-container">
        <div className="flex md:hidden py-12">
          <Link to="/" className="flex rounded mx-auto mb-32 overflow-hidden" id="mobile-footer-logo">
            {pageHeaderLayout?.logo ? (
              <Image
                noDefaultDimensions
                src={pageHeaderLayout?.logo}
                alt={storeInfo?.storename}
                className="overflow-hidden object-contain h-44"
              />
            ) : (
              <div className="md:font-medium text-20 text-white ">{storeInfo?.storename}</div>
            )}
          </Link>
        </div>
        <div className=" md:justify-between justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-1 md:px-4">
            <div className="md:block hidden">
              <Link to="/" id="footer-logo">
                {pageHeaderLayout?.logo ? (
                  <Image
                    noDefaultDimensions
                    src={pageHeaderLayout?.logo}
                    alt={storeInfo?.storename}
                    className=" h-44 rounded"
                  />
                ) : (
                  <div className="font-medium text-30 text-white">{storeInfo?.storename}</div>
                )}
              </Link>
            </div>
            <div className="mb-50 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Menu
              </div>
              {arePoliciesPresent && !policyTos?.privacy && (
                <Link
                  to="/policy/privacy"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-privacy"
                >
                  Privacy
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.termsAndConditions && (
                <Link
                  to="/policy/tnc"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-terms-conditions"
                >
                  Terms & Conditions
                </Link>
              )}
              {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                <Link
                  to="/contactus"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-contact-us"
                >
                  Contact Us
                </Link>
              ) : null}
              {getActiveNavbar('About Us', filterBar) && (
                <Link
                  to="/aboutus"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-about-us"
                >
                  About Us
                </Link>
              )}
              {getActiveNavbar('FAQs', filterBar) && (
                <Link
                  to="/faqs"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-faqs"
                >
                  FAQs
                </Link>
              )}
            </div>
            <div className="mb-50 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Account
              </div>
              <Link
                to="/track-order"
                className={quickLinks}
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-track-order"
              >
                Track Order
              </Link>
              {arePoliciesPresent && !policyTos?.cancelAndRefund && (
                <Link
                  to="/policy/cancellation"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-cancellation-refund"
                >
                  Cancellation &amp; Refund
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
                <Link
                  to="/policy/shipping"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-shipping-delivery"
                >
                  Shipping &amp; Delivery
                </Link>
              )}
            </div>
            <div className="mb-10 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Company
              </div>
              <div className=" text-priceText ">
                <p
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-contact-address"
                >
                  {storeInfo?.contactInfo?.address}
                </p>
                {storeInfo?.contactInfo?.number ? (
                  <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                    <a href={`tel:${storeInfo?.contactInfo?.number}`} id="footer-contact-number">
                      {storeInfo?.contactInfo?.number}
                    </a>
                  </p>
                ) : null}
                <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                  <a
                    href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                      storeInfo?.contactInfo?.email
                    }`}
                    className="underline"
                  >
                    <p className="" id="footer-contact-mail">
                      {storeInfo?.contactInfo?.email}
                    </p>
                  </a>
                </p>
              </div>
              <div>
                <span className="hidden md:flex mt-0 mb-20 md:mt-20 md:mb-0">
                  {storeInfo?.hasSocialLinks ? (
                    <div className="flex items-center justify-start md:justify-center flex-no-wrap">
                      <div className="flex items-center opacity-50 footer-socials">
                        {storeInfo?.social?.facebook ? (
                          <a
                            href={storeInfo?.social?.facebook}
                            title="Facebook"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                          >
                            {/* <FacebookFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Facebook}
                              id="footer-facebook-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.twitter ? (
                          <a
                            href={storeInfo?.social?.twitter}
                            title="Twitter"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                          >
                            {/* <TwitterFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Twitter}
                              id="footer-twitter-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.instagram ? (
                          <a
                            href={storeInfo?.social?.instagram}
                            title="Instagram"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                          >
                            {/* <InstagramFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Instagram}
                              id="footer-instagram-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.pinterest ? (
                          <a
                            href={storeInfo?.social?.pinterest}
                            title="Pinterest"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                          >
                            {/* <PinterestFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-10  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Pinterest}
                              id="footer-pinterest-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.youtube ? (
                          <a
                            href={storeInfo?.social?.youtube}
                            title="YouTube"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                          >
                            {/* <YoutubeFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-20 md:h-20 w-17 h-17"
                              src={Youtube}
                              id="footer-youtube-link"
                            />
                          </a>
                        ) : null}
                        {storeInfo?.social?.linkedin ? (
                          <a
                            href={storeInfo?.social?.linkedin}
                            title="LinkedIn"
                            target="_black()"
                            className="bg-iconbg rounded w-30 h-30 mr-10 md:mr-16 flex justify-center items-center"
                          >
                            {/* <LinkedinFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24 h-18" /> */}
                            <Image
                              noDefaultDimensions={true}
                              className="md:w-24 md:h-16 w-16 h-15"
                              src={Linkedin}
                              id="footer-linkedin-link"
                            />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {/* <InstamojoLogo /> */}
                </span>
              </div>
            </div>
            <div>
              <span className="flex md:hidden mt-0 mb-0 md:mt-20 md:mb-0 ">
                {storeInfo?.hasSocialLinks ? (
                  <div className="flex items-center w-full justify-center flex-no-wrap">
                    <div className="flex items-center opacity-50 footer-socials">
                      {storeInfo?.social?.facebook ? (
                        <a
                          href={storeInfo?.social?.facebook}
                          title="Facebook"
                          target="_black()"
                          className="bg-iconbg rounded w-30 h-30 mr-25 md:mr-34 flex justify-center items-center"
                        >
                          {/* <FacebookFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                          <Image
                            noDefaultDimensions={true}
                            className="w-20 h-20"
                            src={Facebook}
                            id="mobile-footer-facebook-link"
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.twitter ? (
                        <a
                          href={storeInfo?.social?.twitter}
                          title="Twitter"
                          target="_black()"
                          className="bg-iconbg rounded w-30 h-30  mr-25 md:mr-34 flex justify-center items-center"
                        >
                          {/* <TwitterFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                          <Image
                            noDefaultDimensions={true}
                            className="w-20 h-20"
                            src={Twitter}
                            id="mobile-footer-twitter-link"
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.instagram ? (
                        <a
                          href={storeInfo?.social?.instagram}
                          title="Instagram"
                          target="_black()"
                          className="bg-iconbg rounded w-30 h-30  mr-25 md:mr-34 flex justify-center items-center"
                        >
                          {/* <InstagramFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                          <Image
                            noDefaultDimensions={true}
                            className="w-20 h-20"
                            src={Instagram}
                            id="mobile-footer-instagram-link"
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.pinterest ? (
                        <a
                          href={storeInfo?.social?.pinterest}
                          title="Pinterest"
                          target="_black()"
                          className="bg-iconbg rounded w-30 h-30  mr-25 md:mr-34 flex justify-center items-center"
                        >
                          {/* <PinterestFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-10  h-18" /> */}
                          <Image
                            noDefaultDimensions={true}
                            className="w-20 h-20"
                            src={Pinterest}
                            id="mobile-footer-pinterest-link"
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.youtube ? (
                        <a
                          href={storeInfo?.social?.youtube}
                          title="YouTube"
                          target="_black()"
                          className="bg-iconbg rounded w-30 h-30  mr-25 md:mr-34 flex justify-center items-center"
                        >
                          {/* <YoutubeFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                          <Image
                            noDefaultDimensions={true}
                            className="w-20 h-20"
                            src={Youtube}
                            id="mobile-footer-youtube-link"
                          />
                        </a>
                      ) : null}
                      {storeInfo?.social?.linkedin ? (
                        <a
                          href={storeInfo?.social?.linkedin}
                          title="LinkedIn"
                          target="_black()"
                          className="bg-iconbg rounded w-30 h-30   md:mr-34 flex justify-center items-center"
                        >
                          {/* <LinkedinFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24 h-18" /> */}
                          <Image
                            noDefaultDimensions={true}
                            className="w-20 h-20"
                            src={Linkedin}
                            id="mobile-footer-linkedin-link"
                          />
                        </a>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {/* <InstamojoLogo /> */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ pageHeader }: RootState) => ({ pageHeader }))(Footer);
