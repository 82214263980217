export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 280;
export const CATEGORY_IMAGE_HEIGHT = 200;

export const BANNER_IMAGE_WIDTH = 608;
export const BANNER_IMAGE_HEIGHT = 436;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 220;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 124;

export const MOBILE_BANNER_IMAGE_WIDTH = 390;
export const MOBILE_BANNER_IMAGE_HEIGHT = 375;

export const TESTIMONIALS_IMAGE_WIDTH = 122;
export const TESTIMONIALS_IMAGE_HEIGHT = 122;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 122;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 122;

export const DEFAULT_LAYOUT_SELECTION = 10;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH8';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB1';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS5';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS7';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial7';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'capitalize font-semibold';
export const SLIDER_TOP_PADDING = 'pt-0';
export const LAYOUT_CONFIG = {};
export const COMMON_TOAST_MESSAGE = {};
